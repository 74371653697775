import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import {
	DataGridPremium,
	DataGridPremiumProps,
	GridCellParams,
	GridEventListener,
	GridRowHeightParams,
	GridRowModel,
	GridRowModes,
	GridRowModesModel,
	GridRowParams,
	GridRowTreeNodeConfig,
	GridSelectionModel,
	MuiEvent,
	useGridApiRef,
} from '@mui/x-data-grid-premium'

import { useDataGridRowFocus } from 'hooks'
import useDataGridState from 'hooks/UseDataGridState'
import useLanguage from 'hooks/UseLanguge'

import NoData from '../no-data-message/NoData'
import EditToolbar from './EditToolBar'
import {
	INVESTOR_BUDGET_CONTEXT_STATE_NAME,
	INVESTOR_BUDGET_INITIALSTATE,
	REALIZATION_BUDGET_CONTEXT_STATE_NAME,
	REALIZATION_BUDGET_INITIALSTATE,
} from './initial-state'

interface Props {
	projectId: string
	columns: any
	api: any
	budgetData: any
	dataLoading: boolean
	setBudgetData: (value: any) => void
	setRerender: Dispatch<SetStateAction<number>>
	disabledAddButton: boolean
	setDisabledAddButton: Dispatch<SetStateAction<boolean>>
	setIsFormDialogOpen: Dispatch<SetStateAction<boolean>>
	setFormDialogData: Dispatch<SetStateAction<any>>
	setIsImportDialogVisible: Dispatch<SetStateAction<boolean>>
	measurementUnits: any
	industries: any
	vatRate: any
	setIsReorderDialogOpen: Dispatch<SetStateAction<boolean>>
	setRowIdToChangeOrder: Dispatch<SetStateAction<string | undefined>>
	setChangeHistoryDialogData: (value: any) => void
	setIsChangeHistoryDialogOpen: (value: boolean) => void
	setSelectedEntryId: (value: string) => void
	importErrosList: any
	readOnly: boolean
	budgetType: string
}

const DataGridComponent = ({
	projectId,
	columns,
	api,
	budgetData,
	dataLoading,
	setBudgetData,
	setRerender,
	disabledAddButton,
	setDisabledAddButton,
	setIsFormDialogOpen,
	setFormDialogData,
	setIsImportDialogVisible,
	measurementUnits,
	industries,
	vatRate,
	setIsReorderDialogOpen,
	setRowIdToChangeOrder,
	setChangeHistoryDialogData,
	setIsChangeHistoryDialogOpen,
	setSelectedEntryId,
	importErrosList,
	readOnly,
	budgetType,
}: Props) => {
	const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})
	const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([])
	const localStorageData = localStorage.getItem('config_muiTreeData')

	const apiRef = useGridApiRef()

	const location = useLocation()

	const { handleFocusOnSelectedRow } = useDataGridRowFocus(budgetData, apiRef)

	const initialState = budgetType === 'realization' ? REALIZATION_BUDGET_INITIALSTATE : INVESTOR_BUDGET_INITIALSTATE

	const contextStateName =
		budgetType === 'realization' ? REALIZATION_BUDGET_CONTEXT_STATE_NAME : INVESTOR_BUDGET_CONTEXT_STATE_NAME

	const { dataGridStateLoading, handleSortColumns, getDataGridState, dataGridState } = useDataGridState()

	const { t } = useTranslation(['project', 'translation'])

	const { dataGridLanguage } = useLanguage()

	const groupingColDef: DataGridPremiumProps['groupingColDef'] = {
		headerName: t('BudgetTabGeneral.GroupingColumn') as string,
		width: 300,
		minWidth: 130,
		headerAlign: 'center',
	}

	const handleRowEditStart = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
		event.defaultMuiPrevented = true
	}

	const handleCellDoubleClick = (params: GridCellParams, e: any) => {
		const Id = params.row.Id

		if (readOnly) {
			return
		}

		setDisabledAddButton(true)
		setRowModesModel({ ...rowModesModel, [Id]: { mode: GridRowModes.Edit } })
	}

	const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
		event.defaultMuiPrevented = true
	}

	const processRowUpdate = async (newRow: GridRowModel) => {
		if (newRow.isNew) {
			let createdRow: any = newRow
			delete newRow.isNew
			try {
				const response = await api.addRow({
					...newRow,
					MeasurementUnitId: newRow.MeasurementUnitId || null,
					IndustryId: newRow.IndustryId || null,
					VatRateId: newRow.VatRateId || null,
				})
				createdRow = response.NewItem
				setBudgetData(budgetData.map((row: any) => (row.Id === newRow.Id ? response.NewItem : row)))
				setRerender((prev: any) => prev + 1)
				api.showSuccessInfo('saved')
			} catch (err) {
				console.error(err)
			}
			return createdRow
		} else {
			const updatedRow = { ...newRow, isNew: false }
			try {
				await api.updateRow(newRow.Id, newRow)
				setBudgetData(budgetData.map((row: any) => (row.Id === newRow.Id ? updatedRow : row)))
				setRerender((prev: any) => prev + 1)
				api.showSuccessInfo('saved')
			} catch (err) {
				console.error(err)
			}
			return updatedRow
		}
	}

	const isCellEditable = (params: GridCellParams) => {
		if (
			(params.row.HasChildren && params.field === 'Category') ||
			(params.row.HasChildren && params.field === 'CostType') ||
			(params.row.HasChildren && params.field === 'MeasurementUnitId') ||
			(params.row.HasChildren && params.field === 'Amount') ||
			(params.row.HasChildren && params.field === 'Price') ||
			(params.row.HasChildren && params.field === 'IndustryId')
		) {
			return false
		} else {
			return true
		}
	}

	const isGroupExpandedByDefault: any = (node: GridRowTreeNodeConfig) => {
		if (localStorageData?.includes(node.id as string)) {
			return true
		} else {
			return false
		}
	}

	const columnActionsProps = {
		rowModesModel: rowModesModel,
		setRowModesModel: setRowModesModel,
		budgetData: budgetData,
		disabledAddButton: disabledAddButton,
		setDisabledAddButton: setDisabledAddButton,
		setBudgetData: setBudgetData,
		apiRef: apiRef,
		projectId: projectId,
		api: api,
		setIsFormDialogOpen: setIsFormDialogOpen,
		setFormDialogData: setFormDialogData,
		setIsReorderDialogOpen: setIsReorderDialogOpen,
		setRowIdToChangeOrder: setRowIdToChangeOrder,
		setChangeHistoryDialogData,
		setIsChangeHistoryDialogOpen,
		setSelectedEntryId,
	}

	const defaultColumns = columns(columnActionsProps, measurementUnits, vatRate || undefined, industries || undefined)

	const sortedColumns = dataGridState?.columns?.orderedFields
		? handleSortColumns(defaultColumns, dataGridState?.columns?.orderedFields)
		: defaultColumns

	useEffect(() => {
		let storage: any
		if (localStorageData) {
			storage = JSON.parse(localStorageData)
		} else {
			storage = { rowsExpandedByDefault: [] }
		}
		apiRef.current.subscribeEvent('rowExpansionChange', node => {
			if (node.childrenExpanded) {
				storage?.rowsExpandedByDefault.push(node.id)
			} else {
				const deleteRowIdFromStorage = storage.rowsExpandedByDefault.filter((item: any) => {
					return item !== node.id
				})
				storage = {
					rowsExpandedByDefault: [...deleteRowIdFromStorage],
				}
			}
			localStorage.setItem('config_muiTreeData', JSON.stringify(storage))
		})
	}, [apiRef])

	useEffect(() => {
		getDataGridState(contextStateName)
	}, [])

	useEffect(() => {
		const rowIdToFocus = location.state?.rowIdToFocus
		if (rowIdToFocus && budgetData) handleFocusOnSelectedRow(rowIdToFocus)
	}, [budgetData, location.state])

	useEffect(() => {
		if (dataGridState) {
			apiRef.current.restoreState(dataGridState)
		}
	}, [dataGridState])

	return (
		<DataGridPremium
			apiRef={apiRef}
			editMode="row"
			getTreeDataPath={row => row.TreePath}
			treeData
			columns={sortedColumns}
			rows={budgetData}
			loading={dataLoading || dataGridStateLoading}
			sx={{
				minHeight: '500px',
				height: 'calc(100vh - 230px)',
				border: 'none',
			}}
			groupingColDef={groupingColDef}
			checkboxSelection
			onSelectionModelChange={newSelectionModel => {
				setSelectionModel(newSelectionModel)
			}}
			onCellDoubleClick={handleCellDoubleClick}
			selectionModel={selectionModel}
			getRowId={row => row.Id}
			isCellEditable={isCellEditable}
			onRowEditStart={handleRowEditStart}
			onRowEditStop={handleRowEditStop}
			processRowUpdate={processRowUpdate}
			rowModesModel={rowModesModel}
			onRowModesModelChange={newModel => setRowModesModel(newModel)}
			experimentalFeatures={{ newEditingApi: true, aggregation: true }}
			components={{
				Toolbar: EditToolbar,
				NoRowsOverlay: () => <NoData />,
			}}
			componentsProps={{
				toolbar: {
					setBudgetData,
					setRowModesModel,
					projectId,
					disabledAddButton,
					setDisabledAddButton,
					setIsImportDialogVisible,
					api,
					selectionModel,
					budgetData,
					importErrosList,
					contextStateName,
					initialState,
					apiRef,
				},
			}}
			sortingMode="server"
			initialState={initialState}
			rowHeight={35}
			getRowHeight={(params: GridRowHeightParams) => {
				if (params.model.Description?.length > 50) {
					return 'auto'
				}
				return null
			}}
			isGroupExpandedByDefault={isGroupExpandedByDefault}
			localeText={dataGridLanguage.components.MuiDataGrid.defaultProps.localeText}
		/>
	)
}

export default DataGridComponent
