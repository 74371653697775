import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CancelIcon from '@mui/icons-material/Cancel'
import SaveIcon from '@mui/icons-material/Save'
import { indigo, red, teal } from '@mui/material/colors'
import {
	DataGridPremium,
	DataGridPremiumProps,
	GRID_AGGREGATION_FUNCTIONS,
	GridCellParams,
	GridColumnHeaderParams,
	GridEventListener,
	GridRenderCellParams,
	GridRowId,
	GridRowModel,
	GridRowModes,
	GridRowModesModel,
	GridRowParams,
	GridRowTreeNodeConfig,
	GridValueGetterParams,
	MuiEvent,
	useGridApiRef,
} from '@mui/x-data-grid-premium'

import { AnnexesDialog, ColumnHeaderRender, LinkCellRender, LinkEntryDialog, TooltipButton } from 'components/shared'
import ChangeHistoryDialog from 'components/shared/change-history/ChangeHistoryDialog'
import NoData from 'components/shared/no-data-message/NoData'
import { PATHS } from 'data'
import { BudgetCostTypeDataSource } from 'data/lookup-data-sources'
import { useAppSelector, useLinkDocumentsActions } from 'features'
import { useDataGridState, useLocalStorageState } from 'hooks'
import useLanguage from 'hooks/UseLanguge'
import { IContract, IContractEntry, IEntriesAnnexesState } from 'models'
import { enqueueSnackbar } from 'notistack'
import { useContractEntriesService, useMeasurementUnitsService } from 'services'
import formatAmountNumberDataGrid from 'utils/formatAmountNumberDataGrid'
import formatNumberDataGrid from 'utils/formatNumberDataGrid'
import prepareTitleString from 'utils/prepareTitleString'

import ContractEntriesActions from './ContractEntriesActions'
import ContractEntriesToolbar from './ContractEntriesToolbar'
import Annexes from './annexes/Annexes'
import { CONTEXT_STATE_NAME, INITIALSTATE } from './initial-state'

interface Props {
	contractId: string
	readOnly: boolean
}

const ContractEntries = ({ contractId, readOnly }: Props) => {
	const [entriesData, setEntriesData] = useState<IContractEntry[]>([])
	const [isActionButtonDisabled, setIsActionButtonDisabled] = useState(false)
	const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})
	const [measurementUnits, setMeasurementUnits] = useState<any[]>([])

	const [dataLoading, setDataLoading] = useState(true)
	const [isChangeHistoryDialogOpen, setIsChangeHistoryDialogOpen] = useState(false)

	const [selectedEntryId, setSelectedEntryId] = useState('')
	const [selectedEntryData, setSelectedEntryData] = useState<IContractEntry>()

	const [entryDataForAnnexesDialog, setEntryDataForAnnexesDialog] = useState<IEntriesAnnexesState>()
	const [changeHistoryDialogData, setChangeHistoryDialogData] = useState<IContractEntry>()
	const [isTreeData, setIsTreeData] = useLocalStorageState('listTreeData', false)

	const { documentData } = useAppSelector(state => state.documentData)
	const { copiedDocumentData, isLinkEntryDialogOpen } = useAppSelector(state => state.linkDocuments)
	const { setIsLinkEntryDialogOpen } = useLinkDocumentsActions()

	const localStorageData = localStorage.getItem('config_contract_entries')

	const { dataGridStateLoading, getDataGridState, dataGridState, getSortedColumns } = useDataGridState()

	const { t } = useTranslation(['contract', 'translation'])

	const { getMeasurementUnitsLookup } = useMeasurementUnitsService()
	const { dataGridLanguage } = useLanguage()
	const { getContractEntries, updateContractEntry, addContractEntry, showSuccessInfo, linkToBudgetEntry } =
		useContractEntriesService()

	const apiRef = useGridApiRef()

	const groupingColDef: DataGridPremiumProps['groupingColDef'] = {
		headerName: t('ContractEntries.GroupingColumn') as string,
		minWidth: 130,
		width: 300,
		headerAlign: 'center',
	}

	const handlePrepareLinkToBudgetEntry = (params: any) => {
		const data = documentData as IContract

		if (copiedDocumentData.id && copiedDocumentData.documentType === 'ProjectBudget') {
			setSelectedEntryData(params.row)
			setSelectedEntryId(params.id as string)
			setIsLinkEntryDialogOpen(true)
		} else {
			enqueueSnackbar(
				`${t('LinkDocumentDialog.CopyBudgetEntryDataFirst', { ns: 'translation' })} ${data.ProjectLabel || ''}`,
				{
					variant: 'warning',
					persist: true,
				}
			)
		}
	}

	const handleLinkToBudgetEntry = useCallback(async () => {
		try {
			await linkToBudgetEntry(selectedEntryId, copiedDocumentData.id)
			showSuccessInfo('action')
		} catch (err) {
			console.error(err)
		}
	}, [linkToBudgetEntry, selectedEntryId, copiedDocumentData])

	const handleRowEditStart = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
		event.defaultMuiPrevented = true
	}

	const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
		event.defaultMuiPrevented = true
	}

	const handleCellDoubleClick = (params: GridCellParams, e: any) => {
		const Id = params.row.Id
		const isEditable = params.row.CanUpdate && !readOnly

		if (isEditable) setRowModesModel({ ...rowModesModel, [Id]: { mode: GridRowModes.Edit } })
	}

	const handleSaveClick = (id: GridRowId) => () => {
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
		setIsActionButtonDisabled(false)
	}

	const handleCancelClick = (id: GridRowId) => () => {
		setIsActionButtonDisabled(false)
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.View, ignoreModifications: true },
		})

		const editedRow = entriesData.find((row: any) => row.Id === id)
		if (editedRow!.isNew) {
			setEntriesData(entriesData.filter((row: any) => row.Id !== id))
		}
	}

	const processRowUpdate = async (newRow: GridRowModel) => {
		if (newRow.isNew) {
			let createdRow: any = newRow
			delete newRow.isNew
			try {
				const response = await addContractEntry({
					...newRow,
					ContractId: contractId,
				} as IContractEntry)
				createdRow = response
				setEntriesData(entriesData.map((row: any) => (row.Id === newRow.Id ? response : row)))
				showSuccessInfo('saved')
			} catch (err) {
				console.error(err)
			}
			getEntriesData()
			return createdRow
		} else {
			const updatedRow = { ...newRow, isNew: false }
			try {
				await updateContractEntry(newRow.Id, newRow as IContractEntry)
				setEntriesData(entriesData.map((row: any) => (row.Id === newRow.Id ? updatedRow : row)))
				showSuccessInfo('saved')
				getEntriesData()
			} catch (err) {
				console.error(err)
			}
			return updatedRow
		}
	}

	const isCellEditable = (params: GridCellParams) => {
		if (!params.row.CanUpdatePrice && params.field === 'Price') {
			return false
		} else {
			return true
		}
	}

	const budgetValueAggregation = {
		apply: (params: any) => {
			const sum = entriesData
				.filter((el: IContractEntry) => el.BudgetEntryId && el.BudgetValue)
				.map((el: IContractEntry) => el.BudgetValue as number)
				.reduce((a, b) => a + b, 0)

			return sum
		},
		label: 'sum',
		columnTypes: ['number', 'string'],
	}

	const columns: any = [
		{
			field: 'Name',
			headerName: t('ContractEntries.Name'),
			headerAlign: 'center',
			align: 'left',
			valueGetter: (params: any) => {
				const showValue = !params.row.ContractId && !params.row.ParentId ? params.row.BudgetDescription : params.value

				return showValue
			},
			sortable: true,
			editable: true,
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Contract.ContractEntries" />
			),
		},
		{
			field: 'CostType',
			headerName: t('ContractEntries.CostType'),
			headerAlign: 'center',
			align: 'center',
			type: 'singleSelect',
			valueGetter: (params: any) => {
				return params.row.CostType || 'Undefined'
			},
			valueOptions: () =>
				BudgetCostTypeDataSource.map(el => {
					return {
						value: el.value,
						label: t(`ContractEntries.CostTypeEnums.${el.label}`),
					}
				}),
			valueFormatter: ({ value }: any) => {
				const option = BudgetCostTypeDataSource.find((opt: any) => opt.value === value)
				if (option) {
					return t(`ContractEntries.CostTypeEnums.${option.label}`)
				} else {
					return ''
				}
			},
			editable: true,
			sortable: false,
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Contract.ContractEntries" />
			),
		},
		{
			field: 'MeasurementUnitId',
			headerName: t('ContractEntries.MeasurementUnitId'),
			headerAlign: 'center',
			align: 'center',
			type: 'singleSelect',
			valueGetter: (params: any) => {
				return params.row.MeasurementUnitId || null
			},
			valueOptions: measurementUnits,
			valueFormatter: ({ value }: any) => {
				const option = measurementUnits.find((opt: any) => opt.value === value)
				if (option) {
					return option.label
				} else {
					return ''
				}
			},
			editable: true,
			sortable: false,
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Contract.ContractEntries" />
			),
		},
		{
			field: 'Amount',
			headerName: t('ContractEntries.Amount'),
			headerAlign: 'center',
			align: 'right',
			type: 'number',
			valueGetter: (params: any) => {
				const showValue = !params.row.ContractId && !params.row.ParentId ? params.row.BudgetAmount : params.value

				return showValue
			},
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				return formatAmountNumberDataGrid(value)
			},
			editable: true,
			sortable: false,
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Contract.ContractEntries" />
			),
		},
		{
			field: 'Price',
			headerName: t('ContractEntries.Price'),
			headerAlign: 'center',
			align: 'right',
			type: 'number',
			valueGetter: (params: any) => {
				const showValue = !params.row.ContractId && !params.row.ParentId ? params.row.BudgetPrice : params.value

				return showValue
			},
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				return formatNumberDataGrid(value, 4, 4)
			},
			editable: true,
			sortable: false,
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Contract.ContractEntries" />
			),
		},
		{
			field: 'Value',
			headerName: `${t('ContractEntries.Value')}`,
			headerAlign: 'center',
			align: 'right',
			type: 'number',
			valueGetter: (params: GridValueGetterParams) => {
				if (params.row.ContractId) {
					return params.value
				} else {
					return null
				}
			},
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				return formatNumberDataGrid(value)
			},
			sortable: false,
			editable: true,
			availableAggregationFunctions: ['sum', 'avg', 'min', 'max', 'size'],
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Contract.ContractEntries" />
			),
		},
		{
			field: 'ValueOfParts',
			headerName: `${t('ContractEntries.ValueOfParts')}`,
			headerAlign: 'center',
			align: 'right',
			type: 'number',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				return formatNumberDataGrid(value)
			},
			sortable: false,
			editable: false,
			availableAggregationFunctions: ['sum', 'avg', 'min', 'max', 'size'],
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Contract.ContractEntries" />
			),
		},
		{
			field: 'BudgetValue',
			headerName: `${t('ContractEntries.BudgetValue')}`,
			headerAlign: 'center',
			align: 'right',
			type: 'number',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				return formatNumberDataGrid(value)
			},
			sortable: false,
			editable: false,
			availableAggregationFunctions: ['budgetValue', 'avg', 'min', 'max', 'size'],
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Contract.ContractEntries" />
			),
		},
		{
			field: 'ValueOfAnnex',
			headerName: t('ContractEntries.ValueOfAnnex'),
			headerAlign: 'center',
			align: 'right',
			type: 'number',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				return formatNumberDataGrid(value)
			},
			sortable: false,
			editable: false,
			availableAggregationFunctions: ['sum', 'avg', 'min', 'max', 'size'],
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Contract.ContractEntries" />
			),
		},
		{
			field: 'Parameters',
			headerName: t('ContractEntries.Parameters'),
			headerAlign: 'center',
			align: 'center',
			sortable: false,
			editable: true,
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Contract.ContractEntries" />
			),
		},
		{
			field: 'BudgetEntryId',
			headerName: t('ContractEntries.BudgetEntryId'),
			headerAlign: 'center',
			align: 'center',
			sortable: true,
			editable: false,
			renderCell: (params: GridRenderCellParams<any>) => (
				<LinkCellRender
					path={PATHS.project}
					id={documentData?.Project as string}
					documentLabel={documentData?.ProjectLabel as string}
					celllabel={params.row.BudgetEntryIdLabel}
					state={{ tab: 1, rowIdToFocus: params.value }}
				/>
			),
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Contract.ContractEntries" />
			),
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: '',
			cellClassName: 'actions',
			getActions: (params: GridRowParams) => {
				const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit

				if (isInEditMode) {
					return [
						<TooltipButton title="general.Save" onClick={handleSaveClick(params.id)} IconComponent={SaveIcon} />,
						<TooltipButton title="general.Cancel" onClick={handleCancelClick(params.id)} IconComponent={CancelIcon} />,
					]
				} else if (!params.row.ContractId && !params.row.ParentId) {
					return []
				}

				return [
					<ContractEntriesActions
						params={params}
						isActionButtonDisabled={isActionButtonDisabled}
						setIsActionButtonDisabled={setIsActionButtonDisabled}
						setRowModesModel={setRowModesModel}
						contractId={contractId}
						apiRef={apiRef}
						setEntriesData={setEntriesData}
						entriesData={entriesData}
						setSelectedEntryId={setSelectedEntryId}
						setEntryDataForAnnexesDialog={setEntryDataForAnnexesDialog}
						setIsChangeHistoryDialogOpen={setIsChangeHistoryDialogOpen}
						setChangeHistoryDialogData={setChangeHistoryDialogData}
						handlePrepareLinkToBudgetEntry={handlePrepareLinkToBudgetEntry}
					/>,
				]
			},
		},
	]

	const orderedColumns = getSortedColumns(columns)

	const getEntriesData = useCallback(async () => {
		try {
			setDataLoading(true)
			const response = await getContractEntries(contractId, isTreeData)
			setEntriesData(response)
		} catch (err) {
			console.error(err)
		}
		setDataLoading(false)
	}, [contractId, getContractEntries, isTreeData])

	const getMeasurementUnitsList = useCallback(async () => {
		try {
			const response = await getMeasurementUnitsLookup()
			setMeasurementUnits(response)
		} catch (err) {
			console.error(err)
		}
	}, [getMeasurementUnitsLookup])

	const isGroupExpandedByDefault: any = (node: GridRowTreeNodeConfig) => {
		if (localStorageData?.includes(node.id as string)) {
			return true
		} else {
			return false
		}
	}

	useEffect(() => {
		let storage: any
		if (localStorageData) {
			storage = JSON.parse(localStorageData)
		} else {
			storage = { rowsExpandedByDefault: [] }
		}
		apiRef.current.subscribeEvent('rowExpansionChange', node => {
			if (node.childrenExpanded) {
				storage?.rowsExpandedByDefault.push(node.id)
			} else {
				const deleteRowIdFromStorage = storage.rowsExpandedByDefault.filter((item: any) => {
					return item !== node.id
				})
				storage = {
					rowsExpandedByDefault: [...deleteRowIdFromStorage],
				}
			}
			localStorage.setItem('config_contract_entries', JSON.stringify(storage))
		})
	}, [apiRef])

	useEffect(() => {
		getDataGridState(CONTEXT_STATE_NAME)
		getMeasurementUnitsList()
	}, [])

	useEffect(() => {
		getEntriesData()
	}, [contractId, isTreeData])

	useEffect(() => {
		if (dataGridState) {
			apiRef.current.restoreState(dataGridState)
		}
	}, [dataGridState])

	return (
		<>
			<DataGridPremium
				apiRef={apiRef}
				editMode="row"
				getTreeDataPath={row => row.TreePath}
				treeData
				columns={orderedColumns}
				rows={entriesData}
				getRowId={row => row.Id}
				loading={dataLoading || dataGridStateLoading}
				onCellDoubleClick={handleCellDoubleClick}
				isCellEditable={isCellEditable}
				onRowEditStart={handleRowEditStart}
				onRowEditStop={handleRowEditStop}
				processRowUpdate={processRowUpdate}
				rowModesModel={rowModesModel}
				onRowModesModelChange={newModel => setRowModesModel(newModel)}
				experimentalFeatures={{ newEditingApi: true, aggregation: true }}
				sx={{
					minHeight: '500px',
					height: 'calc(100vh - 250px)',
					border: 'none',
					'& .partEntry': {
						color: teal[300],
					},
					'& .rowFromBudget': {
						color: indigo[300],
					},
					'& .balanceError': {
						color: red[500],
					},
				}}
				getRowClassName={params => {
					if (params.row.ParentId) {
						return `partEntry`
					} else if (!params.row.ContractId && !params.row.ParentId) {
						return `rowFromBudget`
					} else if (
						params.row.ValueOfParts &&
						!params.row.ValueOfAnnex &&
						params.row.Value !== params.row.ValueOfParts
					) {
						return 'balanceError'
					} else {
						return 'simpleRow'
					}
				}}
				groupingColDef={groupingColDef}
				components={{
					Toolbar: ContractEntriesToolbar,
					NoRowsOverlay: () => <NoData />,
				}}
				aggregationFunctions={{
					...GRID_AGGREGATION_FUNCTIONS,
					budgetValue: budgetValueAggregation,
				}}
				componentsProps={{
					toolbar: {
						apiRef,
						isActionButtonDisabled,
						setIsActionButtonDisabled,
						setEntriesData,
						setRowModesModel,
						isTreeData,
						setIsTreeData,
						getEntriesData,
						contractId,
						readOnly,
					},
				}}
				initialState={INITIALSTATE}
				getAggregationPosition={groupNode => (groupNode == null ? 'footer' : null)}
				rowHeight={35}
				isGroupExpandedByDefault={isGroupExpandedByDefault}
				localeText={dataGridLanguage.components.MuiDataGrid.defaultProps.localeText}
			/>
			{isLinkEntryDialogOpen && (
				<LinkEntryDialog
					isOpen={isLinkEntryDialogOpen}
					linkFunction={handleLinkToBudgetEntry}
					itemName={`${t('Contract', {
						ns: 'routes',
					})} ${selectedEntryData?.TreePath.slice(-1)} ${prepareTitleString(selectedEntryData?.Name as string)}`}
				/>
			)}
			{entryDataForAnnexesDialog?.isOpen && (
				<AnnexesDialog
					isOpen={entryDataForAnnexesDialog.isOpen}
					setIsOpen={setEntryDataForAnnexesDialog}
					refreshEntriesData={getEntriesData}
					title={`${t('EntriesAnnexesDialog.Title')} ${entryDataForAnnexesDialog.row?.TreePath.slice(-1)}`}>
					<Annexes
						contractId={contractId}
						entryData={entryDataForAnnexesDialog}
						readOnly={!entryDataForAnnexesDialog.row?.CanAddAnnexes}
					/>
				</AnnexesDialog>
			)}
			{isChangeHistoryDialogOpen && (
				<ChangeHistoryDialog
					contextId={selectedEntryId}
					isOpen={isChangeHistoryDialogOpen}
					setIsOpen={setIsChangeHistoryDialogOpen}
					title={`${changeHistoryDialogData?.TreePath.slice(-1)}`}
					translationFile="contract"
					translationPath="ContractEntries"
				/>
			)}
		</>
	)
}

export default ContractEntries
