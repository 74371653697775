import { GridRowParams } from '@mui/x-data-grid-premium'

import { RenderProgress } from 'components/shared'
import { BudgetCostTypeDataSource, BudgetEntryCategoryDataSource } from 'data/lookup-data-sources'
import formatAmountNumberDataGrid from 'utils/formatAmountNumberDataGrid'
import formatNumberDataGrid from 'utils/formatNumberDataGrid'

import i18next from '../../../i18n'
import Actions from '../../shared/budget-data-grid/Actions'

const RealizationBudgetColumns = (actionsProps?: any, measurementUnits?: any, vatRate?: any, industries?: any) => {
	const columns = [
		{
			field: 'Description',
			headerName: i18next.t('project:RealizationBudgetTab.Columns.Description'),
			headerAlign: 'center',
			editable: true,
			sortable: false,
		},
		{
			field: 'Category',
			headerName: i18next.t('project:RealizationBudgetTab.Columns.Category'),
			headerAlign: 'center',
			align: 'center',
			type: 'singleSelect',
			valueGetter: (params: any) => {
				return params.row.Category || 'Undefined'
			},
			valueOptions: () =>
				BudgetEntryCategoryDataSource.map((el: any) => ({
					value: el.value,
					label: i18next.t(`project:RealizationBudgetTab.Columns.CategoryEnums.${el.label}`),
				})),
			valueFormatter: ({ value }: any) => {
				const option = BudgetEntryCategoryDataSource.find((opt: any) => opt.value === value)
				if (option) {
					return i18next.t(`project:RealizationBudgetTab.Columns.CategoryEnums.${option.label}`)
				} else {
					return ''
				}
			},
			editable: true,
			sortable: false,
		},
		{
			field: 'CostType',
			headerName: i18next.t('project:RealizationBudgetTab.Columns.CostType'),
			headerAlign: 'center',
			align: 'center',
			type: 'singleSelect',
			valueGetter: (params: any) => {
				return params.row.CostType || 'Undefined'
			},
			valueOptions: () =>
				BudgetCostTypeDataSource.map((el: any) => ({
					value: el.value,
					label: i18next.t(`project:RealizationBudgetTab.Columns.CostTypeEnums.${el.label}`),
				})),
			valueFormatter: ({ value }: any) => {
				const option = BudgetCostTypeDataSource.find((opt: any) => opt.value === value)
				if (option) {
					return i18next.t(`project:RealizationBudgetTab.Columns.CostTypeEnums.${option.label}`)
				} else {
					return ''
				}
			},
			editable: true,
			sortable: false,
		},
		{
			field: 'IndustryId',
			headerName: i18next.t('project:RealizationBudgetTab.Columns.IndustryId'),
			headerAlign: 'center',
			align: 'center',
			type: 'singleSelect',
			valueGetter: (params: any) => {
				return params.row.IndustryId || ''
			},
			valueOptions: industries,
			valueFormatter: ({ value }: any) => {
				const option = industries.find((opt: any) => opt.value === value)
				if (option) {
					return option.label
				} else {
					return ''
				}
			},
			editable: true,
			sortable: false,
		},
		{
			field: 'MeasurementUnitId',
			headerName: i18next.t('project:RealizationBudgetTab.Columns.MeasurementUnitId'),
			headerAlign: 'center',
			align: 'center',
			type: 'singleSelect',
			valueGetter: (params: any) => {
				return params.row.MeasurementUnitId || ''
			},
			valueOptions: measurementUnits,
			valueFormatter: ({ value }: any) => {
				const option = measurementUnits.find((opt: any) => opt.value === value)
				if (option) {
					return option.label
				} else {
					return ''
				}
			},
			editable: true,
			sortable: false,
		},
		{
			field: 'Amount',
			headerName: i18next.t('project:RealizationBudgetTab.Columns.Amount'),
			headerAlign: 'center',
			align: 'right',
			type: 'number',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				return formatAmountNumberDataGrid(value)
			},
			editable: true,
			sortable: false,
		},
		{
			field: 'Price',
			headerName: i18next.t('project:RealizationBudgetTab.Columns.Price'),
			headerAlign: 'center',
			align: 'right',
			type: 'number',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				return formatNumberDataGrid(value, 4, 4)
			},
			editable: true,
			sortable: false,
		},
		{
			field: 'Budget',
			headerName: i18next.t('project:RealizationBudgetTab.Columns.Budget'),
			headerAlign: 'center',
			align: 'right',
			type: 'number',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				return formatNumberDataGrid(value)
			},
			editable: false,
			sortable: false,
		},
		{
			field: 'RealizationDateStart',
			headerName: i18next.t('project:RealizationBudgetTab.Columns.RealizationDateStart'),
			headerAlign: 'center',
			align: 'center',
			type: 'date',
			valueGetter: ({ value }: any) => {
				const date = new Date(value)
				const dateTimestamp = Date.UTC(
					date.getUTCFullYear(),
					date.getUTCMonth(),
					date.getUTCDate(),
					date.getUTCHours(),
					date.getUTCMinutes(),
					date.getUTCSeconds()
				)
				const newValue = dateTimestamp + 43200000
				if (value) {
					return new Date(newValue)
				}
			},
			editable: true,
			sortable: false,
		},
		{
			field: 'RealizationDateEnd',
			headerName: i18next.t('project:RealizationBudgetTab.Columns.RealizationDateEnd'),
			headerAlign: 'center',
			align: 'center',
			type: 'date',
			valueGetter: ({ value }: any) => {
				const date = new Date(value)
				const dateTimestamp = Date.UTC(
					date.getUTCFullYear(),
					date.getUTCMonth(),
					date.getUTCDate(),
					date.getUTCHours(),
					date.getUTCMinutes(),
					date.getUTCSeconds()
				)
				const newValue = dateTimestamp + 43200000
				if (value) {
					return new Date(newValue)
				}
			},
			editable: true,
			sortable: false,
		},
		{
			field: 'RealizationLengthDays',
			headerName: i18next.t('project:RealizationBudgetTab.Columns.RealizationLengthDays'),
			headerAlign: 'center',
			align: 'center',
			type: 'number',
			valueGetter: (params: any) => {
				if (params.row.RealizationDateStart && params.row.RealizationDateEnd) {
					const dateStartTimestamp = Date.parse(params.row.RealizationDateStart)
					const dateEndTimestamp = Date.parse(params.row.RealizationDateEnd)
					const result = (dateEndTimestamp - dateStartTimestamp) / 86400000 + 1
					return result.toFixed()
				}
			},
			editable: false,
			sortable: false,
		},
		{
			field: 'ExecutionStatusPerc',
			headerName: i18next.t('project:RealizationBudgetTab.Columns.ExecutionStatusPerc'),
			headerAlign: 'center',
			align: 'center',
			type: 'number',
			renderCell: RenderProgress,
			editable: false,
			sortable: false,
		},
		{
			field: 'Plan',
			headerName: i18next.t('project:RealizationBudgetTab.Columns.Plan'),
			headerAlign: 'center',
			align: 'right',
			type: 'number',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				return formatNumberDataGrid(value)
			},
			editable: false,
			sortable: false,
		},
		{
			field: 'ExecutionPlan',
			headerName: i18next.t('project:RealizationBudgetTab.Columns.ExecutionPlan'),
			headerAlign: 'center',
			align: 'right',
			type: 'number',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				return formatNumberDataGrid(value)
			},
			editable: false,
			sortable: false,
		},
		{
			field: 'DemandAndContracting',
			headerName: i18next.t('project:RealizationBudgetTab.Columns.DemandAndContracting'),
			headerAlign: 'center',
			align: 'center',
			editable: false,
			sortable: false,
		},
		{
			field: 'Incomes',
			headerName: i18next.t('project:RealizationBudgetTab.Columns.Incomes'),
			headerAlign: 'center',
			align: 'right',
			type: 'number',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				return formatNumberDataGrid(value)
			},
			editable: false,
			sortable: false,
		},
		{
			field: 'CostsSum',
			headerName: i18next.t('project:RealizationBudgetTab.Columns.CostsSum'),
			headerAlign: 'center',
			align: 'right',
			type: 'number',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				return formatNumberDataGrid(value)
			},
			editable: false,
			sortable: false,
		},
		{
			field: 'SubcontractorsContractsValue',
			headerName: i18next.t('project:RealizationBudgetTab.Columns.SubcontractorsContractsValue'),
			headerAlign: 'center',
			align: 'right',
			type: 'number',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				return formatNumberDataGrid(value)
			},
			editable: false,
			sortable: false,
		},
		{
			field: 'SubcontractorsExecutionValue',
			headerName: i18next.t('project:RealizationBudgetTab.Columns.SubcontractorsExecutionValue'),
			headerAlign: 'center',
			align: 'right',
			type: 'number',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				return formatNumberDataGrid(value)
			},
			editable: false,
			sortable: false,
		},
		{
			field: 'SubcontractorsCostsValue',
			headerName: i18next.t('project:RealizationBudgetTab.Columns.SubcontractorsCostsValue'),
			headerAlign: 'center',
			align: 'right',
			type: 'number',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				return formatNumberDataGrid(value)
			},
			editable: false,
			sortable: false,
		},
		{
			field: 'EquipmentContractsValue',
			headerName: i18next.t('project:RealizationBudgetTab.Columns.EquipmentContractsValue'),
			headerAlign: 'center',
			align: 'right',
			type: 'number',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				return formatNumberDataGrid(value)
			},
			editable: false,
			sortable: false,
		},
		{
			field: 'EquipmentCostsValue',
			headerName: i18next.t('project:RealizationBudgetTab.Columns.EquipmentCostsValue'),
			headerAlign: 'center',
			align: 'right',
			type: 'number',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				return formatNumberDataGrid(value)
			},
			editable: false,
			sortable: false,
		},
		{
			field: 'MaterialContractsValue',
			headerName: i18next.t('project:RealizationBudgetTab.Columns.MaterialContractsValue'),
			headerAlign: 'center',
			align: 'right',
			type: 'number',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				return formatNumberDataGrid(value)
			},
			editable: false,
			sortable: false,
		},
		{
			field: 'MaterialCostsValue',
			headerName: i18next.t('project:RealizationBudgetTab.Columns.MaterialCostsValue'),
			headerAlign: 'center',
			align: 'right',
			type: 'number',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				return formatNumberDataGrid(value)
			},
			editable: false,
			sortable: false,
		},
		{
			field: 'GeneralCostsValue',
			headerName: i18next.t('project:RealizationBudgetTab.Columns.GeneralCostsValue'),
			headerAlign: 'center',
			align: 'right',
			type: 'number',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				return formatNumberDataGrid(value)
			},
			editable: false,
			sortable: false,
		},
		{
			field: 'GeneralContractsValue',
			headerName: i18next.t('project:RealizationBudgetTab.Columns.GeneralContractsValue'),
			headerAlign: 'center',
			align: 'right',
			type: 'number',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				return formatNumberDataGrid(value)
			},
			editable: false,
			sortable: false,
		},
		{
			field: 'actions',
			type: 'actions',
			pinnable: true,
			getActions: (params: GridRowParams) => {
				return [
					<Actions
						params={params}
						rowModesModel={actionsProps.rowModesModel}
						setRowModesModel={actionsProps.setRowModesModel}
						budgetData={actionsProps.budgetData}
						disabledAddButton={actionsProps.disabledAddButton}
						setDisabledAddButton={actionsProps.setDisabledAddButton}
						setBudgetData={actionsProps.setBudgetData}
						apiRef={actionsProps.apiRef}
						projectId={actionsProps.projectId}
						api={actionsProps.api}
						setIsFormDialogOpen={actionsProps.setIsFormDialogOpen}
						setFormDialogData={actionsProps.setFormDialogData}
						selectEnums={{
							measurementUnits: [...measurementUnits],
							costTypes: [...BudgetCostTypeDataSource],
							categories: [...BudgetEntryCategoryDataSource],
							industries: [...industries],
						}}
						setIsReorderDialogOpen={actionsProps.setIsReorderDialogOpen}
						setRowIdToChangeOrder={actionsProps.setRowIdToChangeOrder}
						setChangeHistoryDialogData={actionsProps.setChangeHistoryDialogData}
						setIsChangeHistoryDialogOpen={actionsProps.setIsChangeHistoryDialogOpen}
						setSelectedEntryId={actionsProps.setSelectedEntryId}
						translationPath="RealizationBudgetTab.Columns"
						type="budget"
					/>,
				]
			},
		},
	]

	return columns
}

export default RealizationBudgetColumns
