import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { InputAdornment, TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { AutocompleteInput, InputHint } from 'components/shared'
import { useAppSelector, useAutocompleteInputActions } from 'features'
import { useFieldsPermissions, useFormDataChange } from 'hooks'
import { IContract, IMasterCategory, IMasterRegistry } from 'models'
import { useMasterCategoryService, useMasterRegistryService } from 'services'

interface Props {
	formData: IContract
	onInputChange: (e: any) => void
	fieldErrorsList: string[]
}

const ErpData = ({ formData, onInputChange, fieldErrorsList }: Props) => {
	const [masterCategories, setMasterCategories] = useState<IMasterCategory[]>([])
	const [masterRegistryList, setMasterRegistryList] = useState<IMasterRegistry[]>([])

	const { autocompleteInputLoading } = useAppSelector(state => state.autocompleteInput)

	const { updateAutocompleteInputLoading } = useAutocompleteInputActions()
	const { handleAutocompleteInputChange } = useFormDataChange()
	const { getMasterCategoryList } = useMasterCategoryService()
	const { getMasterRegistryList } = useMasterRegistryService()

	const { isEditable, isVisible } = useFieldsPermissions()

	const { t } = useTranslation('contract')

	const getMasterCategoriesData = async (instanceId: string) => {
		if (masterCategories.length === 0 && !autocompleteInputLoading[instanceId]) {
			try {
				updateAutocompleteInputLoading({ instanceId, loading: true })
				const response = await getMasterCategoryList()

				setMasterCategories(response)
			} catch (err) {
				console.error(err)
			} finally {
				updateAutocompleteInputLoading({ instanceId, loading: false })
			}
		}
	}

	const getMasterRegistryData = async (instanceId: string) => {
		if (masterRegistryList.length === 0 && !autocompleteInputLoading[instanceId]) {
			try {
				updateAutocompleteInputLoading({ instanceId, loading: true })
				const response = await getMasterRegistryList()

				setMasterRegistryList(response)
			} catch (err) {
				console.error(err)
			} finally {
				updateAutocompleteInputLoading({ instanceId, loading: false })
			}
		}
	}

	return (
		<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '5px' }}>
			{/* MasterContractSalesInvoiceCategory */}
			{isVisible('MasterContractSalesInvoiceCategory') && (
				<AutocompleteInput
					dimensions={{ xs: 12, sm: 6, md: 3, lg: 3 }}
					initialValue={{
						value: formData.MasterContractSalesInvoiceCategory || '',
						label: formData.MasterContractSalesInvoiceCategoryLabel || '',
					}}
					onOpen={() => getMasterCategoriesData('Fields.MasterContractSalesInvoiceCategory')}
					onChange={handleAutocompleteInputChange}
					options={masterCategories.filter(cat => cat.ForContractSalesInvoice)}
					readOnly={!isEditable('MasterContractSalesInvoiceCategory')}
					instanceId="Fields.MasterContractSalesInvoiceCategory"
					inputLabel={t('Fields.MasterContractSalesInvoiceCategory')}
					name="MasterContractSalesInvoiceCategory"
					labelFieldName="MasterContractSalesInvoiceCategoryLabel"
					hintTranslationPath="Contract.MasterContractSalesInvoiceCategory"
				/>
			)}
			{/* MasterContractSalesInvoiceDocumentSeries */}
			<Grid xs={12} sm={6} md={3} lg={3}>
				{isVisible('MasterContractSalesInvoiceDocumentSeries') && (
					<TextField
						disabled={!isEditable('MasterContractSalesInvoiceDocumentSeries')}
						value={formData?.MasterContractSalesInvoiceDocumentSeries || ''}
						name="MasterContractSalesInvoiceDocumentSeries"
						label={t('Fields.MasterContractSalesInvoiceDocumentSeries')}
						error={fieldErrorsList.includes('MasterContractSalesInvoiceDocumentSeries')}
						onChange={onInputChange}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<InputHint translationPath="Contract.MasterContractSalesInvoiceDocumentSeries" />
								</InputAdornment>
							),
						}}
						fullWidth
					/>
				)}
			</Grid>
			{/* MasterCostInvoiceCategory */}
			<AutocompleteInput
				dimensions={{ xs: 12, sm: 6, md: 3, lg: 3 }}
				initialValue={{
					value: formData?.MasterCostInvoiceCategory || '',
					label: formData?.MasterCostInvoiceCategoryLabel || '',
				}}
				onOpen={() => getMasterCategoriesData('Fields.MasterCostInvoiceCategory')}
				onChange={handleAutocompleteInputChange}
				options={masterCategories.filter(cat => cat.ForCostInvoiceCategory1)}
				readOnly={!isEditable('MasterCostInvoiceCategory')}
				instanceId={'Fields.MasterCostInvoiceCategory'}
				inputLabel={t('Fields.MasterCostInvoiceCategory')}
				name="MasterCostInvoiceCategory"
				labelFieldName="MasterCostInvoiceCategoryLabel"
				hintTranslationPath="Contract.MasterCostInvoiceCategory"
			/>
			{/* MasterCostInvoiceCategory2 */}
			<AutocompleteInput
				dimensions={{ xs: 12, sm: 6, md: 3, lg: 3 }}
				initialValue={{
					value: formData?.MasterCostInvoiceCategory2 || '',
					label: formData?.MasterCostInvoiceCategory2Label || '',
				}}
				onOpen={() => getMasterCategoriesData('Fields.MasterCostInvoiceCategory2')}
				onChange={handleAutocompleteInputChange}
				options={masterCategories.filter(cat => cat.ForCostInvoiceCategory2)}
				readOnly={!isEditable('MasterCostInvoiceCategory2')}
				instanceId={'Fields.MasterCostInvoiceCategory2'}
				inputLabel={t('Fields.MasterCostInvoiceCategory2')}
				name="MasterCostInvoiceCategory2"
				labelFieldName="MasterCostInvoiceCategory2Label"
				hintTranslationPath="Contract.MasterCostInvoiceCategory2"
			/>
			{/* MasterCostInvoiceRegistry */}
			<AutocompleteInput
				dimensions={{ xs: 12, sm: 6, md: 3, lg: 3 }}
				initialValue={{
					value: formData?.MasterCostInvoiceRegistry || '',
					label: formData?.MasterCostInvoiceRegistryLabel || '',
				}}
				onOpen={() => getMasterRegistryData('Fields.MasterCostInvoiceRegistry')}
				onChange={handleAutocompleteInputChange}
				options={masterRegistryList}
				readOnly={!isEditable('MasterCostInvoiceRegistry')}
				instanceId={'Fields.MasterCostInvoiceRegistry'}
				inputLabel={t('Fields.MasterCostInvoiceRegistry')}
				name="MasterCostInvoiceRegistry"
				labelFieldName="MasterCostInvoiceRegistryLabel"
				hintTranslationPath="Contract.MasterCostInvoiceRegistry"
			/>
		</Grid>
	)
}

export default ErpData
