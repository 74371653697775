import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import AddIcon from '@mui/icons-material/Add'
import CancelIcon from '@mui/icons-material/Cancel'
import DeleteIcon from '@mui/icons-material/Delete'
import DescriptionIcon from '@mui/icons-material/Description'
import HistoryIcon from '@mui/icons-material/History'
import SaveIcon from '@mui/icons-material/Save'
import SendIcon from '@mui/icons-material/Send'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { Typography } from '@mui/material'
import { indigo } from '@mui/material/colors'
import {
	DataGridPremium,
	GridCellParams,
	GridEventListener,
	GridRenderCellParams,
	GridRowId,
	GridRowModel,
	GridRowModes,
	GridRowModesModel,
	GridRowParams,
	MuiEvent,
	useGridApiRef,
} from '@mui/x-data-grid-premium'

import { TooltipButton } from 'components/shared'
import ChangeHistoryDialog from 'components/shared/change-history/ChangeHistoryDialog'
import NoData from 'components/shared/no-data-message/NoData'
import { OfferStatus } from 'data/lookup-data-sources/OfferStatus'
import { useDataGridState } from 'hooks'
import useLanguage from 'hooks/UseLanguge'
import { IAction, IInquiriesContractor } from 'models'
import { useDemandService, useInquiriesService } from 'services'
import { formatDateTimeMUIGrid } from 'utils/formatDateTime'
import formatNumberDataGrid from 'utils/formatNumberDataGrid'

import ImportExcelDialog from './ImportExcelDialog'
import QuotationContractorsToolbar from './QuotationContractorsToolbar'
import { CONTEXT_STATE_NAME, INITIALSTATE } from './initial-state'

interface Props {
	demandId: string
	readOnly: boolean
}

const QuotationContractors = ({ demandId, readOnly }: Props) => {
	const [data, setData] = useState<IInquiriesContractor[]>([])
	const [isActionButtonDisabled, setIsActionButtonDisabled] = useState(false)
	const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})
	const [dataLoading, setDataLoading] = useState(true)

	const [isSendRequestActionEnabled, setIsSendRequestActionEnabled] = useState(false)
	const [isImportExcelDialogOpen, setIsImportExcelDialogOpen] = useState(false)
	const [importExcelContractorId, setImportExcelContractorId] = useState('')

	const [selectedEntryData, setSelectedEntryData] = useState<IInquiriesContractor>()
	const [selectedEntryId, setSelectedEntryId] = useState('')

	const [isChangeHistoryDialogOpen, setIsChangeHistoryDialogOpen] = useState(false)

	const { t } = useTranslation(['demand', 'translation'])

	const { dataGridStateLoading, getDataGridState, dataGridState, getSortedColumns } = useDataGridState()
	const {
		deleteQuotationContractor,
		updateQuotationContractor,
		getQuotationContractors,
		addQuotationContractor,
		showSuccessInfo,
	} = useInquiriesService()
	const { getActions, invokeAction } = useDemandService()

	const { dataGridLanguage } = useLanguage()

	const navigate = useNavigate()

	const apiRef = useGridApiRef()

	const showSendRequestInfo = (params: GridRowParams) => {
		if (isActionButtonDisabled) {
			return t('Inquiries.SendTitles.NoEmailBody')
		} else if (!params.row.Email) {
			return t('Inquiries.SendTitles.NoEmail')
		} else if (!isSendRequestActionEnabled) {
			return t('Inquiries.SendTitles.NoPermissions')
		} else {
			return `${t('Inquiries.SendTitles.SendEmailTo')} ${params.row.Email}`
		}
	}

	const handleRowEditStart = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
		event.defaultMuiPrevented = true
	}

	const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
		event.defaultMuiPrevented = true
	}

	const handleCellDoubleClick = (params: GridCellParams, e: any) => {
		const Id = params.row.Id
		const isEditable = !readOnly

		if (isEditable) setRowModesModel({ ...rowModesModel, [Id]: { mode: GridRowModes.Edit } })
	}

	const handleSaveClick = (id: GridRowId) => () => {
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
		setIsActionButtonDisabled(false)
	}

	const handleCancelClick = (id: GridRowId) => () => {
		setIsActionButtonDisabled(false)
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.View, ignoreModifications: true },
		})

		const editedRow = data.find((row: any) => row.Id === id)
		if (editedRow!.isNew) {
			setData(data.filter((row: any) => row.Id !== id))
		}
	}

	const handleDeleteClick = (id: GridRowId) => async () => {
		try {
			await deleteQuotationContractor(id as string)
			setData(data.filter((row: any) => row.Id !== id))
			showSuccessInfo('deleted')
		} catch (err) {
			console.error(err)
		}
	}

	const handleShowHistory = (params: any) => () => {
		setIsChangeHistoryDialogOpen(true)
		setSelectedEntryId(params.id as string)
		setSelectedEntryData(params.row)
	}

	const handleSendRequest = useCallback(
		async (quotationId: string) => {
			try {
				await invokeAction({
					DocumentId: demandId,
					Action: 'SendQuotationRequest',
					Parameters: {
						quotationRequestContractorId: quotationId,
					},
				})
				showSuccessInfo('action')
				getDemandActions()
				getData()
			} catch (err) {
				console.error(err)
			}
		},
		[invokeAction, demandId]
	)

	const handleAddContractor = (params: GridRowParams) => {
		navigate('/dictionaries/contractors', {
			state: { params: params.row, openAddDialog: true },
		})
	}

	const handleOpenImportDialog = (params: GridRowParams) => {
		setImportExcelContractorId(params.id as string)
		setIsImportExcelDialogOpen(true)
	}

	const handleShowOffer = (params: GridRowParams) => {
		const name = params.row.Name && params.row.Name.length > 15 ? `${params.row.Name.slice(0, 14)}...` : params.row.Name
		const email = params.row.Email.length > 15 ? `${params.row.Email.slice(0, 14)}...` : params.row.Email
		navigate(`/quotationRequestOfferDraft/${params.row.TokenPrivate}`, {
			state: { headerPostFix: ` do ${name || email}` },
		})
	}

	const processRowUpdate = async (newRow: GridRowModel) => {
		if (newRow.isNew) {
			let createdRow: any = newRow
			delete newRow.isNew
			try {
				const response = await addQuotationContractor({
					...newRow,
					DemandId: demandId,
				} as IInquiriesContractor)

				createdRow = response
				setData(data.map((row: any) => (row.Id === newRow.Id ? response : row)))
				showSuccessInfo('saved')
				getData()
			} catch (err) {
				console.error(err)
			}
			return createdRow
		} else {
			const updatedRow = { ...newRow, isNew: false }
			try {
				await updateQuotationContractor(newRow.Id, newRow as IInquiriesContractor)
				setData(data.map((row: any) => (row.Id === newRow.Id ? updatedRow : row)))
				showSuccessInfo('saved')
				getData()
			} catch (err) {
				console.error(err)
			}
			return updatedRow
		}
	}

	const columns: any = [
		{
			field: 'Name',
			headerName: t('Inquiries.QuotationContractor.Name'),
			headerAlign: 'center',
			align: 'left',
			sortable: false,
			editable: true,
		},
		{
			field: 'NIP',
			headerName: t('Inquiries.QuotationContractor.NIP'),
			headerAlign: 'center',
			align: 'left',
			sortable: false,
			editable: true,
		},
		{
			field: 'Email',
			headerName: t('Inquiries.QuotationContractor.Email'),
			headerAlign: 'center',
			align: 'left',
			sortable: false,
			editable: true,
		},
		{
			field: 'PhoneNumber',
			headerName: t('Inquiries.QuotationContractor.PhoneNumber'),
			headerAlign: 'center',
			align: 'left',
			sortable: false,
			editable: true,
		},
		{
			field: 'SendDate',
			headerName: t('Inquiries.QuotationContractor.SendDate'),
			headerAlign: 'center',
			align: 'center',
			type: 'date',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				const valueFormatted = formatDateTimeMUIGrid(value)
				return valueFormatted
			},
			sortable: false,
			editable: false,
		},
		{
			field: 'OfferDate',
			headerName: t('Inquiries.QuotationContractor.OfferDate'),
			headerAlign: 'center',
			align: 'center',
			type: 'date',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				const valueFormatted = formatDateTimeMUIGrid(value)
				return valueFormatted
			},
			sortable: false,
			editable: false,
		},
		{
			field: 'OfferDraftLastDate',
			headerName: t('Inquiries.QuotationContractor.OfferDraftLastDate'),
			headerAlign: 'center',
			align: 'center',
			type: 'date',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				const valueFormatted = formatDateTimeMUIGrid(value)
				return valueFormatted
			},
			sortable: false,
			editable: false,
		},
		{
			field: 'OfferNumber',
			headerName: t('Inquiries.QuotationContractor.OfferNumber'),
			headerAlign: 'center',
			align: 'center',
			sortable: false,
			editable: false,
			renderCell: (params: GridRenderCellParams<any>) => {
				return (
					<Typography
						component={'span'}
						color="primary"
						sx={{ cursor: 'pointer', textDecoration: 'underline' }}
						onClick={() =>
							navigate(`/offer/${params.row.OfferId}`, {
								state: { headerPostFix: ` ${params.value}` },
							})
						}>
						{params.value}
					</Typography>
				)
			},
		},
		{
			field: 'OfferDeadline',
			headerName: t('Inquiries.QuotationContractor.OfferDeadline'),
			headerAlign: 'center',
			align: 'center',
			type: 'date',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				return formatDateTimeMUIGrid(value)
			},
			valueSetter: (params: any) => {
				const date = new Date(params.value)
				const dateTimestamp = Date.UTC(
					date.getUTCFullYear(),
					date.getUTCMonth(),
					date.getUTCDate(),
					date.getUTCHours(),
					date.getUTCMinutes(),
					date.getUTCSeconds()
				)
				const newValue = new Date(dateTimestamp + 43200000)
				if (params.value) {
					return { ...params.row, OfferDeadline: newValue }
				}
				return { ...params.row }
			},
			sortable: false,
			editable: true,
		},
		{
			field: 'OfferStatus',
			headerName: t('Inquiries.QuotationContractor.OfferStatus'),
			headerAlign: 'center',
			align: 'center',
			sortable: true,
			editable: false,
			type: 'singleSelect',
			valueOptions: () => OfferStatus,
			valueGetter: (params: any) => {
				return params.row.OfferStatus || 'Unknown'
			},
			valueFormatter: ({ value }: any) => {
				const option = OfferStatus.find((opt: any) => opt.value === value)
				if (option) {
					return t(`Inquiries.QuotationContractor.OfferStatusEnums.${option.label}`)
				} else {
					return ''
				}
			},
		},
		{
			field: 'OfferValue',
			headerName: t('Inquiries.QuotationContractor.OfferValue'),
			headerAlign: 'center',
			align: 'right',
			sortable: true,
			editable: false,
			type: 'number',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				return formatNumberDataGrid(value)
			},
		},
		{
			field: 'Comments',
			headerName: t('Inquiries.QuotationContractor.Comments'),
			headerAlign: 'center',
			align: 'left',
			sortable: false,
			editable: true,
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: '',
			align: 'right',
			cellClassName: 'actions',
			getActions: (params: GridRowParams) => {
				const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit

				const actionsArray = [
					<TooltipButton title="general.Save" onClick={handleSaveClick(params.id)} IconComponent={SaveIcon} />,
					<TooltipButton title="general.Cancel" onClick={handleCancelClick(params.id)} IconComponent={CancelIcon} />,
					<TooltipButton
						title="general.Remove"
						onClick={handleDeleteClick(params.id)}
						IconComponent={DeleteIcon}
						disabled={isActionButtonDisabled || readOnly}
					/>,
					<TooltipButton
						title={showSendRequestInfo(params)}
						onClick={() => handleSendRequest(params.id as string)}
						IconComponent={SendIcon}
						disabled={isActionButtonDisabled || readOnly || !params.row.Email || !isSendRequestActionEnabled}
					/>,
					<TooltipButton
						title="general.AddToContractorsDictionary"
						onClick={() => handleAddContractor(params)}
						IconComponent={AddIcon}
						disabled={isActionButtonDisabled || readOnly}
					/>,
					<TooltipButton
						title="general.ImportExcel"
						onClick={() => handleOpenImportDialog(params)}
						IconComponent={UploadFileIcon}
						disabled={isActionButtonDisabled || readOnly}
					/>,
					<TooltipButton
						title="general.PreviewAndEditing"
						onClick={() => handleShowOffer(params)}
						IconComponent={DescriptionIcon}
						disabled={isActionButtonDisabled || readOnly}
					/>,
					<TooltipButton
						title="ChangeHistory.ChangeHistory"
						onClick={handleShowHistory(params)}
						IconComponent={HistoryIcon}
						disabled={isActionButtonDisabled || readOnly}
					/>,
				]

				if (isInEditMode) {
					return [actionsArray[0], actionsArray[1]]
				} else if (!params.row.ContractorId) {
					if (params.row.SendDate) {
						return [
							actionsArray[4],
							actionsArray[2],
							actionsArray[5],
							actionsArray[6],
							actionsArray[3],
							actionsArray[7],
						]
					} else {
						return [actionsArray[4], actionsArray[2], actionsArray[3], actionsArray[7]]
					}
				} else if (params.row.SendDate) {
					return [actionsArray[2], actionsArray[5], actionsArray[6], actionsArray[3], actionsArray[7]]
				}

				return [actionsArray[2], actionsArray[3], actionsArray[7]]
			},
		},
	]

	const orderedColumns = getSortedColumns(columns)

	const getData = useCallback(async () => {
		try {
			const response = await getQuotationContractors(demandId)
			setData(response)
		} catch (err) {
			console.error(err)
		}
		setDataLoading(false)
	}, [getQuotationContractors, demandId])

	const getDemandActions = useCallback(async () => {
		try {
			const response = await getActions(demandId)
			const requestAction = response?.find((el: IAction) => {
				return el.Name === 'SendQuotationRequest'
			})

			if (requestAction) {
				setIsSendRequestActionEnabled(requestAction.Enabled)
			}
		} catch (err) {
			console.error(err)
		}
	}, [getActions, demandId])

	useEffect(() => {
		getDataGridState(CONTEXT_STATE_NAME)
	}, [])

	useEffect(() => {
		getData()
		getDemandActions()
	}, [demandId])

	useEffect(() => {
		if (dataGridState) {
			apiRef.current.restoreState(dataGridState)
		}
	}, [dataGridState, apiRef])

	return (
		<>
			<DataGridPremium
				apiRef={apiRef}
				editMode="row"
				columns={orderedColumns}
				rows={data}
				getRowId={row => row.Id}
				loading={dataLoading || dataGridStateLoading}
				onCellDoubleClick={handleCellDoubleClick}
				onRowEditStart={handleRowEditStart}
				onRowEditStop={handleRowEditStop}
				processRowUpdate={processRowUpdate}
				rowModesModel={rowModesModel}
				onRowModesModelChange={newModel => setRowModesModel(newModel)}
				experimentalFeatures={{ newEditingApi: true }}
				sx={{
					minHeight: '500px',
					height: 'calc(100vh - 280px)',
					border: 'none',
					'& .rowWithOffer': {
						color: indigo[300],
					},
				}}
				getRowClassName={params => {
					if (params.row.SendDate) {
						return `rowWithOffer`
					} else {
						return 'rowWithoutOffer'
					}
				}}
				components={{
					Toolbar: QuotationContractorsToolbar,
					NoRowsOverlay: () => <NoData />,
				}}
				componentsProps={{
					toolbar: {
						getData,
						setDataLoading,
						apiRef,
						isActionButtonDisabled,
						setIsActionButtonDisabled,
						setData,
						setRowModesModel,
						readOnly,
						demandId,
						isSendRequestActionEnabled,
					},
				}}
				initialState={INITIALSTATE}
				rowHeight={35}
				localeText={dataGridLanguage.components.MuiDataGrid.defaultProps.localeText}
			/>
			{isChangeHistoryDialogOpen && (
				<ChangeHistoryDialog
					contextId={selectedEntryId}
					isOpen={isChangeHistoryDialogOpen}
					setIsOpen={setIsChangeHistoryDialogOpen}
					title={selectedEntryData?.Name as string}
					translationFile="demand"
					translationPath="Inquiries.QuotationContractor"
				/>
			)}
			{isImportExcelDialogOpen && (
				<ImportExcelDialog
					isOpen={isImportExcelDialogOpen}
					setIsOpen={setIsImportExcelDialogOpen}
					contractorId={importExcelContractorId}
					getData={getData}
				/>
			)}
		</>
	)
}

export default QuotationContractors
