import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import AddLinkIcon from '@mui/icons-material/AddLink'
import CancelIcon from '@mui/icons-material/Cancel'
import DeleteIcon from '@mui/icons-material/Delete'
import HistoryIcon from '@mui/icons-material/History'
import SaveIcon from '@mui/icons-material/Save'
import { indigo } from '@mui/material/colors'
import {
	DataGridPremium,
	GridCellParams,
	GridEventListener,
	GridRenderCellParams,
	GridRowId,
	GridRowModel,
	GridRowModes,
	GridRowModesModel,
	GridRowParams,
	MuiEvent,
	useGridApiRef,
} from '@mui/x-data-grid-premium'

import { LinkCellRender, LinkEntryDialog, TooltipButton } from 'components/shared'
import ChangeHistoryDialog from 'components/shared/change-history/ChangeHistoryDialog'
import NoData from 'components/shared/no-data-message/NoData'
import { PATHS } from 'data'
import { contractCostParametersType } from 'data/lookup-data-sources/contractCostParametersType'
import { useAppSelector, useLinkDocumentsActions } from 'features'
import { useDataGridState } from 'hooks'
import useLanguage from 'hooks/UseLanguge'
import { IContract, IContractCostParameters } from 'models'
import { enqueueSnackbar } from 'notistack'
import { useCostParametersService, useVatRateService } from 'services'
import prepareTitleString from 'utils/prepareTitleString'

import CostParametersToolbar from './CostParametersToolbar'
import { CONTEXT_STATE_NAME, INITIALSTATE } from './initial-state'

interface Props {
	contractId: string
	readOnly: boolean
}

const CostParameters = ({ contractId, readOnly }: Props) => {
	const [data, setData] = useState<IContractCostParameters[]>([])
	const [isActionButtonDisabled, setIsActionButtonDisabled] = useState(false)
	const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})
	const [vatRate, setVatRate] = useState<any>([])
	const [dataLoading, setDataLoading] = useState(true)
	const [selectedEntryData, setSelectedEntryData] = useState<IContractCostParameters>()
	const [selectedEntryId, setSelectedEntryId] = useState('')

	const [isChangeHistoryDialogOpen, setIsChangeHistoryDialogOpen] = useState(false)

	const { documentData } = useAppSelector(state => state.documentData)
	const { copiedDocumentData, isLinkEntryDialogOpen } = useAppSelector(state => state.linkDocuments)
	const { setIsLinkEntryDialogOpen } = useLinkDocumentsActions()

	const { dataGridStateLoading, getDataGridState, dataGridState, getSortedColumns } = useDataGridState()

	const { t } = useTranslation(['contract', 'translation'])

	const {
		getCostParameters,
		updateCostParameter,
		addCostParameter,
		deleteCostParameter,
		showSuccessInfo,
		linkToBudgetEntry,
	} = useCostParametersService()
	const { getVatRate } = useVatRateService()
	const { dataGridLanguage } = useLanguage()

	const apiRef = useGridApiRef()

	const handlePrepareLinkToBudgetEntry = (params: any) => {
		const data = documentData as IContract

		if (copiedDocumentData.id && copiedDocumentData.documentType === 'ProjectBudget') {
			setSelectedEntryData(params.row)
			setSelectedEntryId(params.id as string)
			setIsLinkEntryDialogOpen(true)
		} else {
			enqueueSnackbar(
				`${t('LinkDocumentDialog.CopyBudgetEntryDataFirst', { ns: 'translation' })} ${data.ProjectLabel || ''}`,
				{
					variant: 'warning',
					persist: true,
				}
			)
		}
	}

	const handleLinkToBudgetEntry = useCallback(async () => {
		try {
			await linkToBudgetEntry(selectedEntryId, copiedDocumentData.id)
			showSuccessInfo('action')
			getData()
		} catch (err) {
			console.error(err)
		}
	}, [linkToBudgetEntry, selectedEntryId, copiedDocumentData])

	const handleRowEditStart = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
		event.defaultMuiPrevented = true
	}

	const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
		event.defaultMuiPrevented = true
	}

	const handleCellDoubleClick = (params: GridCellParams, e: any) => {
		const Id = params.row.Id

		if (readOnly) {
			return
		}

		setIsActionButtonDisabled(true)
		setRowModesModel({ ...rowModesModel, [Id]: { mode: GridRowModes.Edit } })
	}

	const handleSaveClick = (id: GridRowId) => () => {
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
		setIsActionButtonDisabled(false)
	}

	const handleCancelClick = (id: GridRowId) => () => {
		setIsActionButtonDisabled(false)
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.View, ignoreModifications: true },
		})

		const editedRow = data.find((row: any) => row.Id === id)
		if (editedRow!.isNew) {
			setData(data.filter((row: any) => row.Id !== id))
		}
	}

	const handleDeleteClick = (id: GridRowId) => async () => {
		try {
			await deleteCostParameter(id as string)
			setData(data.filter((row: any) => row.Id !== id))
			showSuccessInfo('deleted')
		} catch (err) {
			console.error(err)
		}
	}

	const handleShowHistory = (params: any) => () => {
		setIsChangeHistoryDialogOpen(true)
		setSelectedEntryId(params.id as string)
	}

	const processRowUpdate = async (newRow: GridRowModel) => {
		if (newRow.isNew) {
			let createdRow: any = newRow
			delete newRow.isNew
			try {
				const response = await addCostParameter({
					...newRow,
					ContractId: contractId,
				} as IContractCostParameters)
				createdRow = response
				setData(data.map((row: any) => (row.Id === newRow.Id ? response : row)))
				showSuccessInfo('saved')
				getData()
			} catch (err) {
				console.error(err)
			}
			return createdRow
		} else {
			const updatedRow = { ...newRow, isNew: false }
			try {
				await updateCostParameter(newRow.Id, newRow as IContractCostParameters)
				setData(data.map((row: any) => (row.Id === newRow.Id ? updatedRow : row)))
				showSuccessInfo('saved')
				getData()
			} catch (err) {
				console.error(err)
			}
			return updatedRow
		}
	}

	const columns: any = [
		{
			field: 'CostParameterType',
			headerName: t('CostParameters.CostParameterType'),
			headerAlign: 'center',
			align: 'center',
			type: 'singleSelect',
			valueGetter: (params: any) => {
				return params.row.CostParameterType || 'Unknown'
			},
			valueOptions: () =>
				contractCostParametersType.map(el => {
					return {
						value: el.value,
						label: t(`CostParameters.CostParameterTypeEnums.${el.label}`),
					}
				}),
			valueFormatter: ({ value }: any) => {
				const option = contractCostParametersType.find((opt: any) => opt.value === value)
				if (option) {
					return t(`CostParameters.CostParameterTypeEnums.${option.label}`)
				} else {
					return ''
				}
			},
			sortable: true,
			editable: true,
		},
		{
			field: 'VatRateId',
			headerName: t('CostParameters.VatRateId'),
			headerAlign: 'center',
			align: 'center',
			type: 'singleSelect',
			valueGetter: (params: any) => {
				return params.row.VatRateId || ''
			},
			valueOptions: vatRate,
			valueFormatter: ({ value }: any) => {
				const option = vatRate.find((opt: any) => opt.value === value)
				if (option) {
					return option.label
				} else {
					return ''
				}
			},
			editable: true,
			sortable: false,
		},
		{
			field: 'BudgetEntryId',
			headerName: t('CostParameters.BudgetEntryId'),
			headerAlign: 'center',
			align: 'center',
			sortable: true,
			editable: false,
			renderCell: (params: GridRenderCellParams<any>) => (
				<LinkCellRender
					path={PATHS.project}
					id={documentData?.Project as string}
					documentLabel={documentData?.ProjectLabel as string}
					celllabel={params.row.BudgetEntryIdLabel}
					state={{ tab: 1, rowIdToFocus: params.value }}
				/>
			),
		},
		{
			field: 'BudgetEntryDescription',
			headerName: t('RealizationBudgetTab.Columns.Description', {
				ns: 'project',
			}),
			headerAlign: 'center',
			align: 'center',
			sortable: true,
			editable: false,
		},
		{
			field: 'MasterArticleId',
			headerName: t('AdditionalCosts.MasterArticleId'),
			headerAlign: 'center',
			align: 'center',
			sortable: true,
			editable: true,
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: '',
			cellClassName: 'actions',
			getActions: (params: GridRowParams) => {
				const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit

				if (isInEditMode) {
					return [
						<TooltipButton title="general.Save" onClick={handleSaveClick(params.id)} IconComponent={SaveIcon} />,
						<TooltipButton title="general.Cancel" onClick={handleCancelClick(params.id)} IconComponent={CancelIcon} />,
					]
				} else {
					return [
						<TooltipButton
							title="general.LinkToBudget"
							onClick={() => handlePrepareLinkToBudgetEntry(params)}
							IconComponent={AddLinkIcon}
							disabled={isActionButtonDisabled}
						/>,
						<TooltipButton
							title="ChangeHistory.ChangeHistory"
							onClick={handleShowHistory(params)}
							IconComponent={HistoryIcon}
							disabled={isActionButtonDisabled}
						/>,
						<TooltipButton
							title="general.Remove"
							onClick={handleDeleteClick(params.id)}
							IconComponent={DeleteIcon}
							disabled={isActionButtonDisabled || readOnly}
						/>,
					]
				}
			},
		},
	]

	const orderedColumns = getSortedColumns(columns)

	const getData = useCallback(async () => {
		try {
			const response = await getCostParameters(contractId)
			setData(response)
		} catch (err) {
			console.error(err)
		}
		setDataLoading(false)
	}, [getCostParameters, contractId])

	const getVatRateData = useCallback(async () => {
		try {
			const response = await getVatRate()
			setVatRate(response.map((el: any) => ({ value: el.Id, label: el.Name })))
		} catch (err) {
			console.error(err)
		}
		setDataLoading(false)
	}, [getVatRate])

	useEffect(() => {
		getDataGridState(CONTEXT_STATE_NAME)
		getVatRateData()
	}, [])

	useEffect(() => {
		getData()
	}, [contractId])

	useEffect(() => {
		if (dataGridState) {
			apiRef.current.restoreState(dataGridState)
		}
	}, [dataGridState])

	return (
		<>
			<DataGridPremium
				apiRef={apiRef}
				editMode="row"
				columns={orderedColumns}
				rows={data}
				getRowId={row => row.Id}
				loading={dataLoading || dataGridStateLoading}
				sx={{
					border: 'none',
					minHeight: '500px',
					height: 'calc(100vh - 250px)',
					'& .rowFromBudget': {
						color: indigo[300],
					},
				}}
				onCellDoubleClick={handleCellDoubleClick}
				onRowEditStart={handleRowEditStart}
				onRowEditStop={handleRowEditStop}
				processRowUpdate={processRowUpdate}
				rowModesModel={rowModesModel}
				onRowModesModelChange={newModel => setRowModesModel(newModel)}
				experimentalFeatures={{ newEditingApi: true }}
				getRowClassName={params => {
					if (params.row.BudgetEntryNumber) {
						return `rowFromBudget`
					} else {
						return 'simpleRow'
					}
				}}
				components={{
					Toolbar: CostParametersToolbar,
					NoRowsOverlay: () => <NoData />,
				}}
				componentsProps={{
					toolbar: {
						apiRef,
						isActionButtonDisabled,
						setIsActionButtonDisabled,
						setRowModesModel,
						setData,
						contractId,
						readOnly,
					},
				}}
				initialState={INITIALSTATE}
				rowHeight={35}
				localeText={dataGridLanguage.components.MuiDataGrid.defaultProps.localeText}
			/>
			{isChangeHistoryDialogOpen && (
				<ChangeHistoryDialog
					contextId={selectedEntryId}
					isOpen={isChangeHistoryDialogOpen}
					setIsOpen={setIsChangeHistoryDialogOpen}
					title={t('CostParameters.CostParameters')}
					translationFile="contract"
					translationPath="CostParameters"
				/>
			)}
			{isLinkEntryDialogOpen && (
				<LinkEntryDialog
					isOpen={isLinkEntryDialogOpen}
					linkFunction={handleLinkToBudgetEntry}
					itemName={`${t('CostParameters.CostParameters')} - ${prepareTitleString(
						t(`CostParameters.CostParameterTypeEnums.${selectedEntryData?.CostParameterType}`)
					)}`}
				/>
			)}
		</>
	)
}

export default CostParameters
