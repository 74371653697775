import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, InputAdornment, Tab, TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { ContactPersons, ContractorForm, InputHint, InputLinkButton } from 'components/shared'
import { PATHS } from 'data'
import { useFieldsPermissions, useFormDataChange } from 'hooks'
import { IDebitNote } from 'models'
import { DEBIT_NOTE_ENDPOINT } from 'services'

import Settlements from './form-tabs/Settlements'

interface Props {
	noteId: string
	formData: IDebitNote
}

const DebitNoteForm = ({ noteId, formData }: Props) => {
	const [selectedTab, setSelectedTab] = useState('0')

	const { isEditable, isVisible } = useFieldsPermissions()

	const { t } = useTranslation('debit-note')

	const { handleInputChange, fieldsError } = useFormDataChange()

	const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
		setSelectedTab(newValue)
		localStorage.setItem('debitNoteFormSelectedTab', newValue)
	}

	const tabs = useMemo(
		() => [
			{
				label: t('BasicInformationFormTabs.Settlements'),
				renderValue: <Settlements formData={formData} />,
				isVisible: true,
			},
			{
				label: t('BasicInformationFormTabs.Contact'),
				renderValue: (
					<ContactPersons documentId={noteId} contractorId={formData.Contractor || ''} endpoint={DEBIT_NOTE_ENDPOINT} />
				),
				isVisible: isVisible('ContactPersons') || isVisible('ContractorContacts'),
			},
		],
		[t, formData]
	)

	useEffect(() => {
		const savedTab = localStorage.getItem('debitNoteFormSelectedTab')
		setSelectedTab(savedTab || '0')
	}, [])

	return (
		<>
			<div className="w-full pb-4">
				<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '5px' }}>
					{/* Number  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('Number') && (
							<TextField
								disabled={true}
								value={formData.Number || ''}
								name="Number"
								label={t('Fields.Number')}
								error={fieldsError.includes('Number')}
								onChange={handleInputChange}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputHint translationPath="DebitNote.Number" />
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{/* DocumentDate  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('DocumentDate') && (
							<TextField
								disabled={!isEditable('DocumentDate')}
								value={formData.DocumentDate || ''}
								name="DocumentDate"
								label={t('Fields.DocumentDate')}
								InputLabelProps={{ shrink: true }}
								type="date"
								error={fieldsError.includes('DocumentDate')}
								onChange={handleInputChange}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputHint translationPath="DebitNote.DocumentDate" />
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{/* StageId */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<TextField
							disabled={true}
							value={formData.StageIdLabel || ''}
							name="StageId"
							label={t('Fields.StageId')}
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="DebitNote.StageId" />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					{/* Workflow */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<TextField
							disabled={true}
							value={formData.WorkflowIdLabel || ''}
							name="WorkflowId"
							label={t('Fields.WorkflowId')}
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<InputLinkButton
											path={PATHS.workflow}
											documentId={formData.WorkflowId}
											documentLabel={formData.WorkflowIdLabel}
										/>
										<InputHint translationPath="DebitNote.WorkflowId" />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					{/* Project */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('Project') && (
							<TextField
								disabled={true}
								value={formData.ProjectLabel || ''}
								name="Project"
								label={t('Fields.Project')}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputLinkButton
												path={PATHS.project}
												documentId={formData.Project}
												documentLabel={formData.ProjectLabel}
											/>
											<InputHint translationPath="DebitNote.Project" />
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{/* Contract */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('Contract') && (
							<TextField
								disabled={true}
								value={formData.ContractLabel || ''}
								name="Contract"
								label={t('Fields.Contract')}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputLinkButton
												path={PATHS.contract}
												documentId={formData.Contract || ''}
												documentLabel={formData.ContractLabel}
											/>
											<InputHint translationPath="DebitNote.Contract" />
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{/* ProcessingProtocol */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('ProcessingProtocol') && (
							<TextField
								disabled={true}
								value={formData.ProcessingProtocolLabel || ''}
								name="ProcessingProtocol"
								label={t('Fields.ProcessingProtocol')}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputLinkButton
												path={PATHS.processingProtocol}
												documentId={formData.ProcessingProtocol || ''}
												documentLabel={formData.ProcessingProtocolLabel}
											/>
											<InputHint translationPath="DebitNote.ProcessingProtocol" />
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
				</Grid>
			</div>
			<ContractorForm
				documentId={noteId}
				contractorId={formData.Contractor}
				contractorLabel={formData.ContractorLabel}
				readOnly={!isEditable('Contractor')}
			/>
			<div className="w-full pb-4">
				<TabContext value={selectedTab}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<TabList onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
							{tabs.map((el, index) => {
								if (el.isVisible) {
									return <Tab key={index} label={el.label} value={index.toString()} />
								} else return null
							})}
						</TabList>
					</Box>
					{tabs.map((el, index) => {
						if (el.isVisible) {
							return (
								<TabPanel key={index} className="py-2.5 px-0" value={index.toString()}>
									{el.renderValue}
								</TabPanel>
							)
						} else return null
					})}
				</TabContext>
			</div>
		</>
	)
}

export default DebitNoteForm
